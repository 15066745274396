<template>
  <div class="flex flex-col max-w-8xl px-10 pb-12" v-if="userLoading">

    <div class="flex flex-col mt-10 text-left">
      <h2 class="text-lg font-semibold">Profile Information</h2>
    </div>

    <form
    action="#"
    @submit.prevent="updateProfile"
    class="flex flex-row flex-wrap mt-2 p-5 bg-gray-200 rounded">
      <div class="flex flex-col w-full md:w-1/2 lg:w-1/3 p-2">
        <label class="lb-text-label">First Name</label>
        <input
        class="rounded"
        v-model="profileForm.firstName"
        type="text"/>
        <span
        class="text-xs text-red-600"
        :class="{ '': $v.profileForm.firstName.$error, 'invisible': !$v.profileForm.firstName.$error }">
          Required
        </span>
      </div>

      <div class="flex flex-col w-full md:w-1/2 lg:w-1/3 p-2">
        <label class="lb-text-label">Last Name</label>
        <input
        class="rounded"
        v-model="profileForm.lastName"
        type="text" />
        <span
        class="text-xs text-red-600"
        :class="{ '': $v.profileForm.lastName.$error, 'invisible': !$v.profileForm.lastName.$error }">
          Required
        </span>
      </div>

      <div class="flex flex-col w-full md:w-1/2 lg:w-1/3 p-2">
        <label class="lb-text-label">Contact Number</label>
        <VuePhoneNumberInput
        v-model="profileForm.contact"
        @update="updatePhoneNumber"
        class="w-full"
        size="md"
        color="#aa9461"
        default-country-code="ZA" />
        <span
        class="text-xs text-red-600"
        :class="{ '': $v.profileForm.contact.$error, 'invisible': !$v.profileForm.contact.$error }">
          Valid number required
        </span>
      </div>

      <div class="flex flex-col w-full pl-2 mt-5 items-start">
        <button v-if="profileUpdateLoading" disabled class="lb-primary-button">Updating</button>
        <button v-else type="submit" class="lb-primary-button">Save</button>
      </div>
    </form>

    <div class="flex flex-col mt-10 text-left">
      <h2 class="text-lg font-semibold">Email</h2>
    </div>

    <form
    action="#"
    @submit.prevent="updateEmail"
    class="flex flex-row flex-wrap mt-2 p-5 bg-gray-200 rounded">

      <div class="flex flex-col w-full md:w-1/2 lg:w-1/3 p-2">
        <label class="lb-text-label">Email</label>
        <input
        class="rounded"
        v-model="emailForm.email"
        type="text" />
        <span
        class="text-xs text-red-600"
        :class="{ '': $v.emailForm.email.$error, 'invisible': !$v.emailForm.email.$error }">
          Valid email required
        </span>
      </div>

      <div class="flex flex-col w-full pl-2 mt-5 items-start">
        <button type="submit" class="lb-primary-button">Save</button>
      </div>
    </form>

    <div class="flex flex-col mt-10 text-left">
      <h2 class="text-lg font-semibold">Password</h2>
    </div>

    <div class="flex flex-row flex-wrap mt-2 p-5 bg-gray-200 rounded">
      <button class="lb-primary-button" @click="updatePassword">Send Reset Password Email</button>
    </div>

    <div class="flex flex-col mt-10 text-left">
      <h2 class="text-lg font-semibold">Delete Account</h2>
    </div>

    <form
    action="#"
    @submit.prevent="deleteUser"
    class="flex flex-col flex-wrap mt-2 p-5 bg-gray-200 rounded">

      <p class="px-2">
        Please enter the word <strong>"{{deletePhrase}}"</strong> in the text box below in order to delete your account.
      </p>


      <div class="flex flex-col w-full md:w-1/2 lg:w-1/3 p-2">
        <label class="lb-text-label">Enter delete phrase</label>
        <input
        class="rounded"
        type="text"
        v-model="deleteInput" />
      </div>

      <div class="w-full mt-5 pl-2">
        <button
        v-if="deleteInput === deletePhrase"
        type="submit"
        class="lb-warning-button pl-2">
          Delete Account
        </button>

        <button
        v-else
        disabled
        class="lb-warning-button-disabled">
          Delete Account
        </button>


        <p class="mt-2">
          <strong>Warning: All your data will be deleted and can't be retrieved</strong>
        </p>
      </div>

    </form>
  </div>
</template>

<script>
import firebase from 'firebase'
import {mapGetters} from 'vuex'
import VuePhoneNumberInput from 'vue-phone-number-input'
import {required, minLength, email} from "vuelidate/lib/validators";

import isNil from 'lodash/isNil'

import {profilesCollection} from "@/firebase"

export default {
  name: "Profile",
  components: {
    VuePhoneNumberInput
  },
  data: () => ({
    profileForm: {
      firstName: '',
      lastName: '',
      contact: '',
      e164ContactNumber: '',
      formatNationalContactNumber: '',
    },
    profileUpdateLoading: false,

    emailForm: {
      email: ''
    },
    emailUpdateLoading: false,


    userLoading: true,
    profileData: [],

    email: null,

    deletePhrase: 'bye',
    deleteInput: '',
  }),
  validations: {
    profileForm: {
      firstName: {required},
      lastName: {required},
      contact: {required, minLength: minLength(9)},
    },
    emailForm: {
      email: {required, email}
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    }),
  },
  methods: {
    updatePhoneNumber(e) {
      this.profileForm.e164ContactNumber = e.e164
      this.profileForm.formatNationalContactNumber = e.formatNational
    },
    updateProfile() {
      this.$v.profileForm.$touch()
      if(this.$v.profileForm.$error) {
        return
      }

      this.profileUpdateLoading = true

      const userID = this.user.data.uid
      // const timeStamp = firebase.firestore.Timestamp.now()

      const profileData = {
        firstName: this.profileForm.firstName,
        lastName: this.profileForm.lastName,
        contact: this.profileForm.contact,
        e164ContactNumber: this.profileForm.e164ContactNumber ? this.profileForm.e164ContactNumber : this.profileForm.contact,
        formatNationalContactNumber: this.profileForm.formatNationalContactNumber ? this.profileForm.formatNationalContactNumber : this.profileForm.contact,
        modified: firebase.firestore.Timestamp.now(),

        // modified: timeStamp,
      }

      profilesCollection
      .doc(userID)
      .update(profileData)
      .then(() => {
        this.profileUpdateLoading = false
        this.$notify({
          group: 'global',
          title: 'Profile Updated',
          type: 'success'
        })
      })
      .catch(err => {
        this.profileUpdateLoading = false
        this.$notify({
          group: 'global',
          title: 'Message Banner',
          type: 'error',
          text: err.message
        })
      })

    },
    updateEmail() {
      this.$v.emailForm.$touch()
      if(this.$v.emailForm.$error) {
        return
      }

        var user = firebase.auth().currentUser
        const email = this.emailForm.email

        user.updateEmail(email)
        .then(() => {
          this.$notify({
            group: 'global',
            type: 'success',
            text: `Your account has been updated to ${email}`
          })
        })
        .catch((err) => {
          this.$notify({
            group: 'global',
            type: 'error',
            text: err.message
          })
        })

    },
    updatePassword() {
      var email = firebase.auth().currentUser.email

      firebase.auth().sendPasswordResetEmail(email)
      .then(() => {
        this.$notify({
          group: 'global',
          type: 'success',
          text: `A password reset email has been sent to ${email}`
        })
      })
      .catch((err) => {
        this.$notify({
          group: 'global',
          type: 'error',
          text: err.message
        })
      })
    },
    deleteUser() {
      var user = firebase.auth().currentUser

      user.delete()
      .then(() => {

        this.$router.replace({name: 'login'})
      })
      .catch((err) => {
        this.$notify({
          group: 'global',
          type: 'error',
          text: err.message
        })
      })
    }
  },
  mounted() {
    const user = this.user

    if(!isNil(user) && user.loggedIn) {
        this.profileForm.firstName = user.profile.firstName
        this.profileForm.lastName = user.profile.lastName
        this.profileForm.contact = user.profile.formatNationalContactNumber,
        this.emailForm.email = user.profile.email
    }
  },
};
</script>
<style>
.scroll{
  overflow-x: scroll;
  width: 100%;
}
</style>